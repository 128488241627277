& {
	overflow-x: hidden;
}
&.accommodation-detail .main {
	display: flex;
	flex-flow: column;
	.booking-section {
		order: 1;
		margin-bottom: 12vh;
	}
	.youtube-video,
	.vimeo-video {
		@extend .container;
		padding-top: 36.25%;
		position: relative;
		overflow: hidden;
		border-radius: 20px 0 20px 0 !important;
		margin: 3vh auto;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;

			width: calc(100% - 30px);
			height: 100%;
			border: 0;
			object-fit: cover;
			border-radius: 20px 0 20px 0 !important;
			max-width: min-content;
			min-width: calc(100% - 30px);
			@include media-breakpoint-down(lg) {
				width: 90%;
				min-width: 90%;
			}
		}
	}
}
// container
.container {
	max-width: 70%;
	@include media-breakpoint-down(lg) {
		max-width: 100%;
	}
	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border-radius: 20px 0 20px 0;
	border: 0;
	.card-image {
		background: transparent;
		border-radius: 20px 0 20px 0;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				to top,
				rgba(#000, 0.35),
				rgba(#fff, 0) 75%
			);
		}
		img {
			background: transparent;
			border-radius: 20px 0 20px 0;
		}
	}
	.card-body {
		margin-top: -4vh;
		.card-title {
			transform: translateY(-3vh);
			display: block;
			position: relative;
			z-index: 200;
			color: $white;
			font-size: 24px;
		}
		.card-subtitle {
			font-weight: 300;
			font-size: 20px;
		}
		.list-icons {
			margin-top: 3vh;
			.icon i {
				color: $lime-green;
				font-weight: 200;
				font-size: 16px;
			}
		}
	}
}
// cards-btn
.card,
.default-card {
	.card-btn {
		padding: 0.25rem 1.85rem;
		font-size: 16px;
	}
}

// images
.gallery-img,
.gallery-link {
	border-radius: 20px 0 20px 0;
	img {
		border-radius: 20px 0 20px 0;
	}
}
