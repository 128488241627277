// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-subtitle,
	.owl-subtitle,
	.owl-description,
	.owl-btn {
		display: none;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 55vh;
	min-height: 540px;
	max-height: 1080px;
	@include media-breakpoint-down(lg) {
		.owl-title {
			font-family: $font-family-secondary;
			font-size: 50px;
			font-weight: 700;
			line-height: 50px;
			text-align: center;
		}
	}

	@include media-breakpoint-up(sm) {
		height: 65vh;
		.owl-title {
			font-size: 64px;
			text-align: center;
			max-width: 100%;
			margin: auto;
			font-weight: 700;
			font-family: $font-family-secondary;
		}
	}
}
