// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors

$dark-blue: #003087;
$light-blue: #00afd7;
$lime-green: #b5bd00;
$bright-yellow: #ffcd00;
$bright-red: #d50032;
$dark-pink: #ce0f69;
$grey: #eeece2;
$white: #fff;
$black: #000;

// new colors
$green-dark: #43451C;
$green-dark-2:#4B4E15;
// theme-colors
$primary: $lime-green;
$secondary: $bright-yellow;

// body
$body-bg: $grey;
$body-color: $black;

// links
$link-color: $green-dark-2;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url("https://fonts.googleapis.com/css2?family=Radio+Canada:ital,wght@0,300..700;1,300..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
$font-family-primary: "Radio Canada", sans-serif;
$font-family-secondary: "Titillium Web", sans-serif;

$font-family-base: $font-family-primary;

$font-size-base: 0.875rem; // 14px

$h1-font-size: $font-size-base * 2.571; // 36px
$h2-font-size: $font-size-base * 2.286; // 32px
$h3-font-size: $font-size-base * 2; // 28px
$h4-font-size: $font-size-base * 1.714; // 24px
$h5-font-size: $font-size-base * 1.429; // 20px
$h6-font-size: $font-size-base * 1.143; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $black;

// contextual
$light: $lime-green;
$dark: $light-blue;
$text-light: $white;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: none;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1366px,
  xxxl: 1920px
) !default;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
