// footer
.footer:not(:has(.grid)) {
	padding: 6vh 0 0 0;
}
.footer {
	background: $green-dark-2;
	padding: 18vh 0 0 0;
	position: relative;
	.grid {
		width: 90%;
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		.item {
			display: none;
			&:first-child() {
				display: block;
				max-width: 80% !important;
				flex-basis: 80% !important;
				@include media-breakpoint-down(lg) {
					display: flex;
					justify-content: center;
				}
				.card {
					border: 4px solid $white;
					background: $bright-yellow;
					box-shadow: 0px 0px 20px 0px #00000040;
					border-radius: 50%;
					width: 220px;
					aspect-ratio: 1/1;
					transform: translateY(-60%) rotate(-10deg);
					padding: 1rem 0.65rem;
					.card-image {
						i {
							font-size: 36px;
							font-weight: 300;
							line-height: 36px;
							color: $white;
						}
					}
					.card-caption {
						display: flex;
						flex-flow: column;
						align-items: center;
						justify-content: center;
						.card-title {
							display: none;
						}
						.card-subtitle {
							font-size: 16px;
							font-weight: 700;
							line-height: 19.2px;
							text-align: left;
							order: 1;
						}
						.card-description {
							font-size: 14px;
							font-weight: 500;
							line-height: 16.8px;
							text-align: left;
							p {
								margin-bottom: 9px !important;
							}
						}
					}
					&:hover {
						scale: 1.05;
						transition: 0.35s ease;
					}
				}
			}
		}
	}
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 50%;
		background: url("/images/background-element-1.svg");
		background-size: contain;
		background-repeat: no-repeat;
		left: 50%;
		bottom: 5vh;
		pointer-events: none;
		transform: translateX(-50%);
		opacity: 0.5;
		@include media-breakpoint-down(lg) {
			background-position: bottom 3rem center;
		}
	}
	.footer-main {
		margin-bottom: 6vh;
		color: $white;
		.footer-text {
			*:not(a) {
				color: $white;
			}
			a,
			a strong {
				color: $lime-green;
			}
			img {
				filter: grayscale(1) brightness(200);
			}
		}
		a {
			font-size: 14px;
			font-weight: 700;
			line-height: 16.8px;
			text-align: left;
			color: $lime-green !important;
		}
		.vocabulary-term-list {
			display: flex;
			gap: 0.8rem;
			align-items: center;
			li {
				.list-item-title {
					display: none;
				}
				i {
					color: $lime-green;
					font-size: 24px;
					transition: 0.35s ease;
				}
				&:hover {
					i {
						transform: scale(1.1);
						transition: 0.35s ease;
					}
				}
			}
		}
		@include media-breakpoint-down(lg) {
			.two {
				margin-top: 6vh;
			}
		}
	}
	.footer-socket {
		background: $white;
		.list {
			display: flex;
			width: 100%;
			justify-content: center;
			gap: 1.25rem;
			padding: 1rem;
			a {
				color: $green-dark-2;
				font-size: 14px;
				font-weight: 400;
				line-height: 16.8px;
				text-align: left;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
					text-underline-offset: 5px;
				}
			}
		}
	}
}
