// btn
.btn {
	box-shadow: $shadow;
	font-weight: 700;
	border-radius: 10px 0px 10px 0px;
	font-size: 18px;
	padding: 10px 37px;
	&:hover {
		border-radius: 0px 10px 0px 10px;
	}
	// btn-primary
	&.btn-primary {
		background: $green-dark;
		border-color: $green-dark;
		color: $white;
		&:hover {
			background: rgba($green-dark, 0.8);
			border-color: $green-dark;
			color: $white;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
	&.btn-white {
		color: $green-dark-2;
		&:hover {
			background: $grey;
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	font-size: 14px;
	font-weight: 700;
	line-height: 16.8px;
	text-align: left;
	text-decoration-line: underline;
	text-decoration-style: solid;

	text-decoration-skip-ink: none;
	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
