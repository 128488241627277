//header
.header {
	z-index: 997;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $grey;
	transition: 0.5s;
	&::before {
		content: "";
		position: absolute;
		width: 15vw;
		height: 60vh;
		background: url("/images/background-element-6.svg");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right;
		right: 0;
		top: 0;
		pointer-events: none;
		transition: 3s ease;

		@include media-breakpoint-down(lg) {
			width: 45%;
		}
	}
	&::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 1px;
		width: 8rem;
		aspect-ratio: 1/1;
		background: $grey;
		transform: translateY(100%);
		border-top-left-radius: 0px;
		-webkit-mask-image: radial-gradient(
			circle 4rem at 100% 100%,
			transparent 0,
			transparent 8rem,
			black 21px
		);
		mask-image: radial-gradient(
			circle 4rem at 100% 100%,
			transparent 0,
			transparent 8rem,
			black 21px
		);
		@include media-breakpoint-down(lg) {
			width: 5rem;
			-webkit-mask-image: radial-gradient(
				circle 4rem at 100% 100%,
				transparent 0,
				transparent 5rem,
				black 21px
			);
			mask-image: radial-gradient(
				circle 4rem at 100% 100%,
				transparent 0,
				transparent 5rem,
				black 21px
			);
		}
	}
	&.sticky,
	&.sticky-mobile {
		box-shadow: $shadow;
		position: fixed;
		@include media-breakpoint-down(lg) {
		}
		&::before {
			transform: translate(110%, -35%) rotate(-20deg);
			transition: 3s ease 1;
			transition-delay: 0.5s;
		}
		.logo {
			width: 120px;
			transition: 0.35s ease;
			@include media-breakpoint-down(lg) {
				max-height: 5vh;
				margin-top: 5px;
			}
		}
		.menu#secondary_menu {
			display: none !important;
		}
	}

	.container,
	.container-fluid {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
		@include media-breakpoint-down(lg) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
		.container-holder {
			> .column {
				align-items: center;
				justify-content: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		position: fixed;
		left: 10px;
		top: 10px;
		.navbar-toggler {
			color: $green-dark-2;
			font-size: 24px;
			font-weight: 400;
			line-height: 24px;
			text-align: left;
			i {
				transition: 0.35s ease;
			}
			&[aria-expanded="true"] {
				i {
					transform: scaleX(-1);
					transition: 0.35s ease;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 206px;
		margin: 25px 10px;
		margin-right: auto;
		transition: 0.35s ease;
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			margin-top: 65px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	// menu
	.menu:not(#secondary_menu) {
		@include media-breakpoint-down(lg) {
			order: 2;
			flex-basis: 100%;
			padding-bottom: 3vh;
			.navbar-nav {
				padding: 1rem;

				.nav-item {
					.nav-link {
						font-family: $font-family-primary;
						color: $green-dark-2;
						font-size: 16px;
						font-weight: 600;
					}

					&.active {
						.nav-link {
							color: $lime-green !important;
							font-weight: 600;
						}
					}

					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;

						&::after,
						&::before {
							display: none;
						}

						.nav-item {
							border-bottom: 0;
							padding-left: 1rem;
						}

						.nav-link {
							font-size: 16px;
							font-weight: 200;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			max-width: fit-content;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			.navbar-nav {
				display: flex;
				gap: 0.8rem;
				.nav-item {
					.nav-link {
						color: $green-dark;
						font-weight: 400;
					}
					&.active,
					&:hover {
						.nav-link {
							text-decoration: 2px underline;
							text-underline-offset: 8px;
							color: $black;
							font-weight: 600;
						}
					}
					.dropdown-menu.show {
						box-shadow: none;
						border: 0;
						background: $grey;
						min-width: 12rem;
						padding: 1rem 2rem;
						border-radius: 0 0 20px 0;
						&::before,
						&::after {
							display: none;
						}
						.nav-item {
							.nav-link {
								text-decoration: none;
							}
							&:hover,
							&.active {
								.nav-link {
									color: $green-dark;
									text-decoration: underline;
								}
							}
						}
					}
				}
			}
		}
	}

	.menu#secondary_menu {
		display: none !important;
		@include media-breakpoint-down(lg) {
			display: block !important;
			position: absolute;
			top: 5px;
			right: 0;
			padding-right: 5rem;
			ul.navbar-nav {
				display: flex !important;
				flex-flow: row wrap !important;
				gap: 0.8rem !important;
			}
			.nav-link .menu-title {
				font-size: 12px !important;
				font-weight: 400 !important;
				line-height: 18.25px !important;
				text-align: left !important;
				color: $green-dark;
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-right: 1.25rem;
		margin-left: auto;
		z-index: 999;
		position: relative;
		a.nav-link {
			display: flex !important;
			border: 0 !important;
			box-shadow: none !important;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: center;
			gap: 0.15rem;
			text-transform: uppercase;
			color: $green-dark;
			font-weight: 700;
			&:hover {
				text-decoration: underline;
				text-underline-offset: 5px;
			}
			&::after {
				content: "\f7a2";
				border: 0;
				font-family: $font-awesome;
				font-size: 14px;
			}
		}
		@include media-breakpoint-down(lg) {
			position: absolute;
			z-index: 1000;
			right: 15px;
			top: 15px;
			a.nav-link {
				font-family: $font-family-secondary;
				font-size: 12px;
				font-weight: 400;
				line-height: 18.25px;
				text-align: left;
			}
		}
	}
}

// book-button{
div.book-button {
	order: 1;
	z-index: 999;
	position: relative;
	a {
		@extend .btn, .btn-primary;
		padding: 0.35rem 1.5rem !important;
		font-size: 14px !important;
		border-radius: 6px, 0px, 6px, 0px !important;
		background: $dark-pink !important;
		border: 0;
	}
	@include media-breakpoint-down(lg) {
		display: none;
	}
}
