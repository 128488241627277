// not eyecatcher-owl-carousel
.owl-carousel:not(.eyecatcher-owl-carousel) {
  min-height: $carousel-minheight;

  .item {
    @extend .h-100;
    min-height: $carousel-minheight;
    padding: $carousel-padding;
  }

  // large
  &.large {
    min-height: $carousel-minheight-large;

    .item {
      min-height: $carousel-minheight-large;
      padding: $carousel-padding-large;
    }
  }
}

// owl-carousel
.owl-carousel {
  &:not(.slider) {
    @extend .loading-overlay;
  }

  .owl-stage {
    @extend .d-flex;

    .owl-item {
      flex: 0 0 auto;
    }
  }

  .item {
    @extend .d-flex;
    align-items: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .owl-caption-holder {
      width: 100%;
    }

    .owl-container {
      @extend .container;
    }

    .owl-caption {
      color: $white;
      text-align: center;
      text-shadow: $shadow;

      .owl-title {
        @extend h2;
        color: inherit;
      }

      .owl-subtitle {
        @extend h3;
        color: inherit;
      }

      .owl-description {
        margin-bottom: 0.5rem;
      }

      .owl-btn {
        @extend .btn;
        @extend .btn-primary;
        text-shadow: none;
      }
    }
  }

  // owl-nav
  .owl-nav {
    &.disabled {
      @extend .d-none;
    }

    .owl-prev,
    .owl-next {
      z-index: 100;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 32px;
      width: 40px;
      text-shadow: $shadow;
      color: $white;
      text-align: center;
      line-height: 40px;
      transition: $transition-base;
      outline: 0;

      &:hover {
        color: $primary;
      }

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }
    }

    .owl-prev {
      left: 0;
    }

    .owl-next {
      right: 0;
    }
  }

  // owl-dots
  .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &.disabled {
      @extend .d-none;
    }

    .owl-dot {
      outline: 0;

      span {
        display: block;
        width: 15px;
        height: 15px;
        margin: 3px;
        border: 2px solid $white;
        border-radius: 15px;
        box-shadow: $shadow;
        transition: $transition-base;
      }

      &:hover,
      &.active {
        span {
          background: $white;
        }
      }
    }
  }

  // owl-carousel.slider
  &.slider {
    min-height: 0;

    .item {
      @extend .align-items-start;
      min-height: 0;
      padding: 0;
    }

    // owl-nav
    .owl-nav {

      .owl-prev,
      .owl-next {
        color: $primary;
        text-shadow: none;

        &:hover {
          color: darken($primary, 15%);
        }
      }

      .owl-prev {
        left: -40px;
      }

      .owl-next {
        right: -40px;
      }
    }

    // owl-dots
    .owl-dots {
      @extend .position-static;
      margin-top: 15px;

      .owl-dot {
        span {
          border-color: $primary;
          box-shadow: none;
        }

        &:hover,
        &.active {
          span {
            background: $primary;
          }
        }
      }
    }
  }

  // owl-carousel.accommodation-gallery
  &.accommodation-gallery {

    &,
    .item {
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      min-height: unset;
      cursor: pointer;
      padding: 0;

      @include media-breakpoint-down(md) {
        aspect-ratio: 36 / 23;
        height: auto;
      }
    }

    .item {
      picture {
        display: block;
        height: 100%;
        
        img {
          height: 100%;
        }
      }
    }

    .owl-stage,
    .owl-stage-outer {
      height: 100%;
    }
    
    .owl-dots {
      .owl-dot {
        span {
          box-shadow: none;
          border-width: 1px;
        }
      }
    }

    .num {
      background-color: #FFF;
      position: absolute;
      right: 10px;
      bottom: 10px;
      padding: 5px 15px;
      border-radius: 4px;
      z-index: 99;
      font-size: 12px;
      line-height: normal;
      font-weight: 700;
    }
  }
}
