.timeslot-datepicker {
	background: $primary;
	border: 1px solid $primary;
}

.day.active {
	color: $white !important;
}

.timeslot-item {
	border: none !important;
}

.timeslot-item-day-times-holder,
.timeslot-item-day-title-holder {
	max-width: 50% !important;
	flex-basis: 50% !important;
}

.timeslot-category {
	flex: unset !important;
	max-width: unset !important;
}

.timeslot-item-holder {
	flex-basis: 50% !important;
	max-width: 50% !important;
	@include media-breakpoint-down(lg) {
		flex-basis: 100% !important;
		max-width: 100% !important;
	}
}
.timeslot-item-day-title {
	background: $primary !important;
	color: $white !important;
}

.timeslot-item-title {
	color: $green-dark-2 !important;
}

.timeslot-item-day-times {
	background: rgba($lime-green, 0.3) !important;
	color: $black !important;
}
