// mini-sab
.mini-sab {
	z-index: 990;
	position: relative;
	height: 63vh;
	margin-top: -65vh;
	max-width: 33%;
	margin-left: auto;
	display: flex;
	align-items: flex-end;
	@include media-breakpoint-down(lg) {
		margin-top: 0;
		height: unset !important;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 0 15px;
		background: $lime-green;
		padding-bottom: 3vh;
		.container {
			margin-top: -22%;
			transform: none;
		}
	}
}

&.home {
	.mini-sab {
		height: 65vh;
		.container {
			transform: translateY(calc(50% + 50px));
			@include media-breakpoint-down(lg) {
				transform: none;
			}
		}
	}
}
