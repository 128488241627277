// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// home usp's
.home-usp-section {
	padding: 6vh 0;
	background: $lime-green;
	border-radius: 0 0 100px 0;
	.column.one {
		flex-basis: 60% !important;
		max-width: 60% !important;
		@include media-breakpoint-down(lg) {
			flex-basis: 100% !important;
			max-width: 100% !important;
		}
	}
	.column.two {
		display: none;
	}
	ul.usps {
		display: flex;
		flex-flow: row wrap;
		row-gap: 1.5rem;
		justify-content: space-between;
		@include media-breakpoint-down(lg) {
			flex-flow: column;
			li {
				flex-basis: 100% !important;
				max-width: 100% !important;
				min-width: 100%;
				margin-left: auto !important;
				margin-right: auto !important;
				justify-content: space-between;

				color: $green-dark-2;
				i {
					min-width: 2.5rem;
					max-width: 2.5rem;
					color: $white;
				}

				a .list-item-short-description,
				.list-item-short-description {
					max-width: 100% !important;
				}
				a .caption,
				.caption {
					margin-right: auto;
				}
			}
		}

		li {
			flex-basis: 50%;
			max-width: 50%;
			display: flex;
			align-items: flex-start;
			padding-right: 0.5rem;
			color: $green-dark-2;
			a {
				display: flex;
				align-items: flex-start;

				text-decoration: none;
			}
			a i,
			i,
			a img,
			img {
				min-width: 80px;
				max-width: 80px;
				font-size: 52px;
				transition: 0.35s ease;
				color: $white;
				font-weight: 200;
				display: flex;
				justify-content: center;
			}
			a.caption,
			.caption {
				display: flex;
				flex-flow: column;
				.list-item-title {
					font-size: 18px;
					font-weight: 700;
				}
				.list-item-short-description {
					max-width: 90%;
					font-weight: 400;
					font-size: 14px;
				}
			}
			&:hover {
				i,
				img {
					transform: translateY(-10%);
					transition: 0.35s ease;
				}
			}
		}
	}
}

// home accommodation sectiopn
@keyframes float {
	0% {
		transform: rotate(0) scale(1);
	}
	50% {
		transform: rotate(180deg) scale(1.5);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}
.home-accommodation-section {
	padding: 6vh 0;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		width: 45vw;
		height: 70%;
		background: url("/images/background-element-2.svg");
		background-size: cover;
		background-repeat: no-repeat;
		left: -22vw;
		top: 35%;
		pointer-events: none;
		animation: float 200s ease infinite;
		@include media-breakpoint-down(lg) {
			width: 50%;
			background-size: contain;
			animation: none;
		}
	}
	.container {
		.column {
			// max-width: 78% !important;
			// @include media-breakpoint-down(lg) {
			// 	max-width: 100% !important;
			// }

			@include media-breakpoint-up(lg) {
				max-width: 78% !important;
			}

			@include media-breakpoint-up(xxl) {
				max-width: 70% !important;
			}
		}
		&.intro {
			.column {
				height: 100%;
				.title {
					max-width: 90%;
					@include media-breakpoint-down(lg) {
						max-width: 100%;
						font-size: 32px;
						font-weight: 700;
						line-height: 32px;
						text-align: left;
					}
				}
				.description {
					max-width: 70%;
					@include media-breakpoint-down(lg) {
						max-width: 100%;
					}
					p {
						margin: 1rem 0;
					}
					a {
						font-weight: 700;
					}
				}
			}
		}
		&.grid {
			.column {
				margin-left: auto;
				.grid-items {
					@include media-breakpoint-down(lg) {
						padding-top: 8vh;
						padding-bottom: 200px;
						justify-content: space-between;
					}
					.item {
						@include media-breakpoint-down(lg) {
							max-width: 48% !important;
							flex-basis: 48% !important;
						}
						.card {
							border-radius: 20px 0px 20px 0px;
							box-shadow: none;
							border: 0;
							position: relative;
							background: $body-bg;
							height: 100%;
							.card-caption {
								display: flex;
								flex-flow: column;
								justify-content: flex-end;
								.card-title {
									word-wrap: normal;
									color: $white;
									font-size: 24px;
									font-weight: 700;
									font-family: $font-family-secondary;
									@include media-breakpoint-down(lg) {
										font-size: 18px;
										font-weight: 700;
										line-height: 20px;
										text-align: left;
									}
								}
								.card-description,
								.card-subtitle {
									display: none;
								}
							}

							.card-image {
								height: 100%;
								img {
									object-fit: cover;
								}
							}
							.card-body {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								pointer-events: none;
							}
						}
						&:nth-child(3n + 2) {
							.card {
								transform: translateY(-8vh);
							}
						}
						&:nth-child(3n + 3) {
							.card {
								transform: translateY(-14vh);
							}
						}
						@include media-breakpoint-down(lg) {
							&:nth-child(2n + 2) {
								.card {
									transform: translateY(-3vh);
								}
							}

							&:nth-child(2n + 3) {
								.card {
									transform: translateY(0);
								}
							}
						}
					}
				}
			}
		}
		.grid.highlight {
			width: 30%;
			height: 100%;
			margin: auto;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			align-items: flex-end;
			transform: translateX(-85%);
			@include media-breakpoint-down(lg) {
				transform: translateY(55%);
				height: max-content;
				width: 100%;
				top: unset;
				bottom: 0;
			}
			.item {
				display: none;

				&:first-child() {
					display: block;
					max-width: 80% !important;
					flex-basis: 80% !important;

					.card {
						border: 4px solid $white;
						background: $bright-yellow;
						box-shadow: 0px 0px 20px 0px #00000040;
						border-radius: 50%;
						width: 200px;
						aspect-ratio: 1/1;
						transform: rotate(-10deg) translate(35%, -50%);
						padding: 0.85rem;
						display: flex;
						align-items: center;
						@include media-breakpoint-down(lg) {
							transform: rotate(-10deg) translate(50%, -35%);
						}
						.card-body {
							position: relative;
						}
						.card-image {
							height: max-content;

							i {
								font-size: 36px;
								font-weight: 300;
								line-height: 36px;
								color: $white;
							}
						}

						.card-caption {
							display: flex;
							flex-flow: column;
							align-items: center;
							justify-content: center;

							.card-title {
								display: none;
							}

							.card-subtitle {
								font-size: 14px;
								font-weight: 700;
								line-height: 19.2px;
								text-align: center;
								order: 1;
								display: block;
							}

							.card-description {
								font-size: 12px;
								font-weight: 500;
								line-height: 16.8px;
								text-align: center;
								display: block;

								p {
									margin-bottom: 9px !important;
								}
							}
						}

						&:hover {
							scale: 1.05;
							transition: 0.35s ease;
						}
					}
				}
			}
		}
	}
}

// eyecacther content
.eyecatcher-content-section {
	.owl-carousel {
		border-radius: 130px 0 0 0;
		overflow: hidden;
		.owl-item {
			min-height: 70vh;
			@include media-breakpoint-down(lg) {
				min-height: 50vh;
			}
			.owl-title {
				font-weight: 700;
				font-size: 80px;
				font-family: $font-family-secondary;
				@include media-breakpoint-down(lg) {
					font-size: 40px;
					font-weight: 700;
					line-height: 40px;
					text-align: center;
				}
			}
			.owl-subtitle {
				font-size: 40px;
				@include media-breakpoint-down(lg) {
					font-size: 20px;
					line-height: 20px;
					text-align: center;
				}
			}
			.owl-btn {
				@extend .btn-white;
			}
		}
	}
}

// home facilities
@keyframes float-lr {
	0% {
		transform: translateX(0) scale(1);
	}
	50% {
		transform: translateX(-20%) scale(1.25);
	}
}
.home-facilities-section {
	padding: 6vh 0;
	background: $lime-green;
	border-radius: 0 0 100px 0;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		width: 25vw;
		height: 50%;
		background: url("/images/background-element-3.svg");
		background-size: cover;
		background-repeat: no-repeat;
		left: -10vw;
		top: 12vh;
		pointer-events: none;
		animation: float-lr 30s ease infinite;
		@include media-breakpoint-down(lg) {
			background-size: contain;
			width: 50%;
		}
	}
	&::after {
		content: "";
		position: absolute;
		width: 15vw;
		height: 70%;
		background: url("/images/background-element-4.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		right: -5rem;
		top: -12vh;
		pointer-events: none;
		animation: float-lr 50s linear infinite;
		@include media-breakpoint-down(lg) {
			right: 0;
			z-index: -1;
			width: 50%;
		}
	}
	.container {
		.container-holder {
			.one {
				max-width: 35% !important;
				flex-basis: 35% !important;
				.title {
					color: $green-dark-2;
					font-size: 48px;
				}
				.description {
					color: $black;
					a {
						font-weight: 700;
						color: $green-dark-2;
					}
				}
			}
			.two {
				max-width: 65% !important;
				flex-basis: 65% !important;
				.vocabulary-term-list {
					display: flex;
					flex-flow: row wrap;
					row-gap: 2rem;
					li {
						max-width: 50% !important;
						flex-basis: 50% !important;
						font-size: 18px;
						display: flex;
						align-items: center;
						font-weight: 700;
						font-family: $font-family-secondary;
						gap: 1.1rem;
						color: $green-dark-2;
						a {
							color: $black;
							text-decoration: none;
							display: flex;
							align-items: center;
							gap: 1.1rem;
						}
						i {
							font-size: 32px;
							width: 35px;
							transition: 0.35s ease;

							color: $white;
						}
						&:hover {
							i,
							img {
								transform: scaleX(-1);
								transition: 0.35s ease;
							}
						}
					}
				}
			}
			@include media-breakpoint-down(lg) {
				.one,
				.two {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
				.two {
					.vocabulary-term-list {
						margin-top: 3vh;
						display: flex;
						flex-flow: row wrap;
						gap: 2rem;
						justify-content: space-between;

						li {
							max-width: 45% !important;
							flex-basis: 45% !important;
							font-size: 18px;
							display: flex;
							align-items: center;
							font-weight: 700;
							font-family: $font-family-secondary;
							gap: 1.1rem;

							font-size: 14px;

							line-height: 16px;
							text-align: left;
						}
					}
				}
			}
		}
	}
	.masonry {
		margin-top: 12vh;
		.card-columns {
			column-count: 2;
			@include media-breakpoint-down(lg) {
				column-count: 1;
			}
			.item {
				.card {
					border-radius: 20px 0 20px 0;
					border: 0;
					position: relative;
					.card-image {
						&::after {
							content: "";
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
							background: linear-gradient(
								to top,
								rgba(#000, 0.3),
								rgba(#fff, 0) 40%
							);
						}
					}
					.card-image,
					img {
						height: 100%;
						object-fit: cover;
					}
					.card-body {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						pointer-events: none;
						padding: 0;
					}
					.card-caption {
						display: flex;
						align-items: flex-end;
						.card-title {
							color: $white;
							font-weight: 700;
							font-size: 32px;
							padding-left: 1rem;
						}
						.card-description {
							display: none;
						}
					}
					.card-btn {
						display: none;
					}
				}
				&:nth-child(1) {
					.card {
						background: none;
						.card-image {
							display: none;
						}
						.card-body {
							position: relative;
							pointer-events: all;
							.card-caption {
								flex-flow: column;
								align-items: flex-start;
							}
						}
						.card-title {
							font-size: 48px;
							font-weight: 700;
							padding-left: unset;
							color: $green-dark-2;
						}
						.card-description {
							display: block;
							max-width: 70%;
							color: $black;
							a {
								color: $green-dark-2;
							}
							@include media-breakpoint-down(lg) {
								max-width: 90%;
							}
						}
						.card-btn {
							display: block;
							padding: 0;
							background: 0;
							border-radius: 0;
							border: 0;
							max-width: fit-content;
							font-weight: 700;
							font-size: 14px;
							text-decoration: underline;
							text-underline-offset: 4px;
							color: $green-dark-2;
							&:hover {
								text-underline-offset: 2px;
							}
						}
					}
				}
				&:nth-child(6n + 2) {
					.card {
						aspect-ratio: 10/7;
					}
				}

				&:nth-child(6n + 3) {
					.card {
						aspect-ratio: 15/13;
					}
				}

				&:nth-child(6n + 4) {
					.card {
						aspect-ratio: 18/11;
					}
				}

				&:nth-child(6n + 5) {
					.card {
						aspect-ratio: 11/10;
					}
				}

				&:nth-child(6n + 6) {
					.card {
						aspect-ratio: 18/11;
					}
				}

				&:nth-child(6n + 7) {
					.card {
						aspect-ratio: 9/10;
					}
				}
			}
		}
	}
	div.read-more {
		margin: 3vh auto 1rem auto;
		a {
			@extend .btn, .btn-primary;
			background: $white;
			max-width: 264px;
			font-size: 16px !important;
			padding: 0.6rem 1.5rem !important;
		}
	}
}
// sale section
.home-sale-section {
	padding: 6vh 0;
	position: relative;
	&::after {
		content: "";
		position: absolute;
		width: 45vw;
		height: 70%;
		background: url("/images/background-element-2.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		left: -5rem;
		bottom: 0vh;
		pointer-events: none;
		animation: float-lr 15s linear infinite;
		@include media-breakpoint-down(lg) {
		}
	}
	.container {
		.container-holder {
			flex-flow: row nowrap;
			@include media-breakpoint-down(lg) {
				flex-flow: column;
			}
			.one {
				max-width: 55% !important;
				flex-basis: 55% !important;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
				.title {
					font-size: 36px;
					font-weight: 700;
					color: $green-dark-2;
				}
				.description {
					max-width: 70%;
					a {
						font-weight: 700;
					}
				}
			}
			.two {
				max-width: 45% !important;
				flex-basis: 45% !important;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
				position: relative;
				.slider {
					min-width: 125%;
					padding-bottom: 12vh;
					left: -25%;
					@include media-breakpoint-down(lg) {
						left: unset !important;
						min-width: unset;
					}
					.owl-stage-outer {
						padding-bottom: 12vh;
						@include media-breakpoint-down(lg) {
							padding-bottom: 6vh;
						}
					}
					.owl-dots,
					.owl-nav {
						display: none;
					}
					.owl-item {
						transition: 0.35s ease;
						&.first {
							transform: translate(0, 12vh);
							transition: 0.35s ease;
							@include media-breakpoint-down(lg) {
								transform: none;
							}
						}
					}
					.card {
						@extend .default-card;
					}
				}
			}
		}
	}
}
// lead-section
// =========================================================================
&.accommodation-detail,
&.accommodation-category-overview,
&.accommodation-category-detail,
&.service-overview,
&.service-category-detail,
&.service-detail {
	.main {
		position: relative;

		&::after {
			content: "";
			position: absolute;
			top: 30%;
			left: -20%;
			width: 40%;
			height: 50%;
			background: url("/images/tree_left.svg");
			background-position: left;
			background-size: contain;
			background-repeat: no-repeat;
			pointer-events: none;
		}

		&::before {
			content: "";
			position: absolute;
			top: 10vh;
			right: 0;
			width: 40%;
			height: 50%;
			background: url("/images/tree_right.svg");
			background-position: right;
			background-size: contain;
			pointer-events: none;
			background-repeat: no-repeat;
		}
	}
	.lead-section {
		background: $lime-green;
		padding-top: 3vh;
		padding-bottom: 3vh;
		// margin: 15px 0 0 0;
		@include media-breakpoint-down(lg) {
			padding-top: 6vh;
			margin: 0;
		}
	}
}

&.service-detail {
	.main {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: flex-start;
		position: relative;

		@include media-breakpoint-down(lg) {
			flex-flow: column;
		}
	}
	.lead-section {
		max-width: 100% !important;
		flex-basis: 100% !important;
		.title {
			max-width: 60%;
			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}
			* {
				line-height: 45px !important;
			}
		}
	}
	.content-section {
		max-width: 30% !important;
		flex-basis: 30% !important;
		margin: 6vh 0;
		@include media-breakpoint-down(lg) {
			max-width: 100% !important;
			flex-basis: 100% !important;
		}
	}
	.gallery-section {
		max-width: 40% !important;
		flex-basis: 40% !important;
		margin: 6vh 0;
		order: 1;
		@include media-breakpoint-down(lg) {
			max-width: 100% !important;
			flex-basis: 100% !important;
			margin: 1rem 0;
			padding: 0 1.5rem;
		}
		.container {
			max-width: 100%;
		}
		.gallery-horizontal {
			.large-item {
				flex: 0 0 100%;
				max-width: 100%;
			}
			.gallery-thumbs {
				flex: 0 0 100%;
				max-width: 100%;
				flex-flow: row wrap;
				.gallery-item {
					flex: 0 0 25%;
					max-width: 25%;
					@include media-breakpoint-down(lg) {
						flex: 0 0 50%;
						max-width: 50%;
					}
				}
			}
		}
	}

	.content-section {
		.container {
			max-width: 100%;
		}
		.container-holder {
			flex-flow: column;
			.one,
			.two {
				flex-basis: 100% !important;
				max-width: 100% !important;
				padding: 10px !important;
				margin-bottom: 0;
				@include media-breakpoint-down(lg) {
					padding: 0 1.5rem !important;
				}
			}
		}
	}
}

.lead-section {
	padding: 6vh 0;
	background: $lime-green;
	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		@include media-breakpoint-down(lg) {
			margin-bottom: 15px;
		}
	}

	.title,
	.page-title * {
		font-family: $font-family-secondary;
		color: $green-dark-2 !important;
		font-size: 40px;
		font-weight: 700;
		line-height: 36px;
		text-decoration-skip-ink: none;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 35px 0 0;
				font-family: $font-family-secondary;
				color: $green-dark-2;

				font-size: 14px;
				font-weight: 700;
			}

			.icon {
				margin-right: 10px;
				color: $white;

				font-size: 20px;
				i {
					font-weight: 200 !important;
				}
			}
		}
		.wysiwyg {
			max-width: 70%;
			margin-right: auto;

			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			text-align: left;
		}
		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
&.accommodation-book {
	.content-section {
		padding: 6vh 0;
	}
}
&:not(.accommodation-book) {
	.content-section {
		margin: 6vh 0;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $green-dark-2;
		}
		ul {
			gap: 1rem;
			display: flex;
			flex-flow: column;
			margin-top: 1rem;
			li {
				font-size: 14px;
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
	.grid-items {
		justify-content: flex-start !important;
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	padding: 6vh 0;
	background: $lime-green;
	border-radius: 0 0 50px 0;
	.title,
	.page-title * {
		font-family: $font-family-secondary;
		color: $green-dark-2 !important;
		font-size: 40px;
		font-weight: 700;
		line-height: 36px;
		text-decoration-skip-ink: none;
	}
}

// gallery-section
// =========================================================================
&.accommodation-detail {
	.gallery-section {
		background: $lime-green;
		margin-top: 0;

		border-radius: 0 0 50px 0;
	}
	.content-section {
		margin-top: 6vh;
	}
}
.gallery-section {
	margin-bottom: calc(-6vh + 30px);
	padding-bottom: 6vh;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
